import { useQuery } from "react-query";
import axios from "axios";

const useFetchData = (endpoint, enabled = true) => {
  return useQuery(
    [endpoint],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`
        );
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: enabled,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useCurrentConditions = (ndx) => {
  const lastFewDataQuery = useFetchData(`display${ndx}-last-few`);
  const pctFullDataQuery = useFetchData(
    `display${ndx}-pct-full`,
    ["003", "004", "005", "006", "007", "008"].includes(ndx)
  );

  const isLoading = [lastFewDataQuery, pctFullDataQuery].some(
    (query) => query.isFetching
  );
  const error = [lastFewDataQuery, pctFullDataQuery].find(
    (query) => query.error
  );

  return {
    lastFewData: lastFewDataQuery,
    pctFullData: pctFullDataQuery,
    error,
    isLoading,
  };
};
