import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope="read:current_user update:current_user create:user_tickets read:logs read:logs_users read:roles read:users update:users read:role_members update:current_user_metadata read:current_user_metadata"
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <HandleRedirection>{children}</HandleRedirection>
    </Auth0Provider>
  );
};

const HandleRedirection = ({ children }) => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const history = useHistory();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const fetchLandingPage = async (landingPageNdx) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-user-landing-pages/${landingPageNdx}`
        );
        return response.data.landing_page_path;
      } catch (error) {
        console.error("Error fetching landing page path", error);
        return "/";
      }
    };

    const redirectOnLogin = async () => {
      if (!isLoading && isAuthenticated && user) {
        const loginInitiated = localStorage.getItem("loginInitiated");

        if (loginInitiated === "true") {
          localStorage.removeItem("loginInitiated");
          setIsRedirecting(true);

          const landingPageNdx =
            user?.["https://lre-up-lowerlatham/landingPageNdx"] || 1;
          const landingPagePath = await fetchLandingPage(landingPageNdx);

          if (history.location.pathname !== landingPagePath) {
            history.replace(landingPagePath);
          }
        }
      }
    };

    redirectOnLogin().then(() => setIsRedirecting(false));
  }, [isAuthenticated, isLoading, user, history]);

  // Render children only if not redirecting
  if (isLoading || isRedirecting) {
    return null;
  }

  return <>{children}</>;
};

export default Auth0ProviderWithHistory;
